@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@font-face {
  font-family: 'Lato-Regular';
  src: url('assets/font/Lato-Regular/Lato-Regular.eot');
  src: url('assets/font/Lato-Regular/Lato-Regular.woff') format('woff'),
    url("assets/font/Lato-Regular/Lato-Regular.ttf") format('truetype'),
    url('assets/font/Lato-Regular/Lato-Regular.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src: url("assets/font/Roboto/Roboto-Regular.ttf") format('truetype')
}




@font-face {
  font-family: 'Almoni';
  src: url('assets/font/Almoni/almoni-dl-aaa-regular.otf');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('assets/font/Lato-Bold/Lato-Bold.eot');
  src: url('assets/font/Lato-Bold/Lato-Bold.woff') format('woff'),
    url("assets/font/Lato-Bold/Lato-Bold.ttf") format('truetype'),
    url('assets/font/Lato-Bold/Lato-Bold.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'NovemberHebrew-Regular';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Regular.woff2');
}

@font-face {
  font-family: 'NovemberHebrew-Bold';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Bold.woff2');
}

/* @import '~antd/dist/antd.css'; */
@font-face {
  font-family: 'NovemberHebrew-Light';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Light.woff2');
}

@font-face {
  font-family: 'Assistant-SemiBold';
  src: url('assets/font/Assistant-SemiBold/Assistant-SemiBold.woff2');
}

@font-face {
  font-family: 'Assistant';
  src: url('assets/font/Assistan/Assistant-Regular.otf');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dragging * {
  cursor: grabbing !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  border: none;
}

* {
  outline: none !important;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: block;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}

.display-none {
  display: none;
}

.Toastify__toast {
  padding: 0 !important;
  border-radius: 4px !important;
}

.Toastify__toast-body {
  margin: 0 !important;
}


.lm_goldenlayout .lm_content {
  background: #EBEFF2;
  border: none;
}

.Toastify__progress-bar--default {
  background: #29CCA3 !important;
}

.custom_item_transformer:hover {
  background-color: #404040;
}

.input_white_to_verify_token {
  background: white !important;
  border: 1px solid #d3dee3 !important;
  border-radius: 4px !important;
  color: #768896 !important;
  width: 100% !important;
}

ui5-side-navigation {
  display: none;
}

.CUSTOM_OPEN_ICON {
  width: 90%;
  display: block;
  margin: auto;
  cursor: pointer;
  max-height: 32px;
  object-fit: contain;
  ;
}

ui5-shellbar {
  display: none;
}

.CUSTOM_ACCORDION_WIDGET .bp3-button-text {
  width: 100%;
}

@font-face {
  font-family: "SAP-icons";
  src: url("https://sap.github.io/fundamental-react/theming-base-content/content/Base/baseLib/sap_fiori_3/fonts/SAP-icons.woff") format("woff");
  /* available in woff, woff2 and ttf */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "72";
  src: url("https://sap.github.io/fundamental-react/theming-base-content/content/Base/baseLib/sap_base_fiori/fonts/72-Regular.woff") format("woff");
  /* Bold, Light, Regular available in woff and woff2 */
  font-weight: normal;
  font-style: normal;
}

/* 
body div#hubspot-messages-iframe-container {
  bottom: 40px !important;
  left: 0 !important;
} */

body .woot-widget-bubble {
  bottom: 50px !important;
  width: 48px !important;
  height: 48px !important;
}

body .woot-widget-bubble svg {
  height: 20px;
  width: 20px;
  margin: 0;
}

body .woot-widget-bubble::before {
  top: 14px !important;
  left: 23px !important;
  height: 20px !important;
}

body .woot-widget-bubble::after {
  top: 14px !important;
  left: 23px !important;
  height: 20px !important;
}

body .intercom-app div:nth-child(2) {
  bottom: 50px !important;
}

body .intercom-lightweight-app-launcher {
  bottom: 50px !important;
  left: 20px !important;
}

body .intercom-launcher-frame {
  bottom: 50px !important;
  left: 0 !important;
}

.Toastify__toast:has(.ant-alert-error) {
  display: none;
}

/* Tour */
.tour-create-service:last-child>.ant-tour-inner>span {
  display: none;
}

.tour-create-service:last-child>.ant-tour-inner>.ant-tour-cover {
  padding: 0 !important;
}

.tour-create-service:last-child>.ant-tour-inner>.ant-tour-footer {
  display: none;
}

.ant-tour-target-placeholder {
  z-index: 1001;
  /* pointer-events: none; */
  pointer-events: unset !important;
}

.tour-step-service-action-buttons:last-child>.ant-tour-inner>.ant-tour-cover {
  padding: 16px 16px 0 !important;
}

@keyframes pulse {
  0% {
    transform: scale(.9);

  }

  50% {
    transform: scale(1);

  }

  100% {
    transform: scale(.9);

  }
}

@keyframes pulse-color {
  0% {
    fill: #6c767e;
  }

  70% {
    fill: #3b82ff;

  }

  100% {
    fill: #6c767e;
  }
}

@keyframes shadow-bg {
  0% {}

  70% {
    box-shadow: 0 0 0 10px rgba(90, 153, 212, 0);

  }

  100% {
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);

  }
}

.services-tour-step.pulsate>span {
  animation: pulse 2s infinite;
}

.services-tour-step.pulsate>span>span>svg {
  box-shadow: 0 0 0 0 rgba(90, 153, 212, .5);
  -webkit-animation: pulse 1.5s infinite;
  animation: shadow-bg 2s infinite;
}

.services-tour-step.pulsate>span>span>svg>path {
  animation: pulse-color 2s infinite;
}

/* Info list */
.info-list-item>span {
  padding-inline-start: 8px !important;
}

.info-list-item>.ant-dropdown-menu-submenu-title {
  padding-inline-start: 20px !important;
}

.info-list-item>.ant-dropdown-menu-submenu-title>.ant-dropdown-menu-submenu-arrow {
  left: 0;
}

.info-list-item>.ant-dropdown-menu-submenu-title>.ant-dropdown-menu-submenu-arrow>span>svg {
  transform: rotate(180deg);
}

/* Sidebar */

.sidebar-service-item>.ant-dropdown-menu-submenu-title {
  padding: 0px 12px !important;
}

.sidebar-service-item>.ant-dropdown-menu-submenu-title>.ant-dropdown-menu-title-content {
  font-size: 10px;
  margin-right: 10px;
}

.sidebar-service-item>.ant-dropdown-menu-title-content {
  font-size: 10px;
}

.tour-template-service>.ant-tour-inner {
  height: 100vh !important;
  width: 100vw !important;
  border-radius: 0 !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, #3ac4fb 100%)
}

.tour-template-service {
  left: 0 !important;
}

.tour-template-service>.ant-tour-inner>.ant-tour-footer {
  display: none !important;
}

.tour-template-service>.ant-tour-inner>.ant-tour-close {
  display: none !important;
}

.tour-template-service>.ant-tour-inner>.ant-tour-cover>div>.ant-row>.ant-col>.ant-card>.ant-card-body>.ant-card-meta>.ant-card-meta-detail>.ant-card-meta-description {
  color: #3b82ff !important;
  font-size: 16px !important;
}

.tour-template-service>.ant-tour-inner>.ant-tour-cover>div>.ant-row>.ant-col>.ant-card>.ant-card-body>.ant-card-meta>.ant-card-meta-detail>.ant-card-meta-title {
  margin-bottom: 24px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}


.template-service-modal {
  top: 0;
  max-width: unset;
  height: 100vh !important;
  width: 100vw !important;
  border-radius: 0 !important;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, #3ac4fb 100%)
}

.template-service-modal .ant-modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
}

.template-service-modal .ant-modal-close {
  display: none;
}

.template-service-modal .ant-modal-footer {
  display: none;
}

.template-service-modal-title1 {
  text-align: center;
  margin-top: 46px;
}

.template-service-modal-title2 {
  text-align: center;
}

.template-service-modal-card .ant-card-meta-title {
  margin-bottom: 24px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.template-service-modal-card .ant-card-meta-description {
  color: #3b82ff !important;
  font-size: 16px !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}