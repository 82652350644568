.dock-tab {
  background: #ececec;
  color: #6C767E;
  /* text-transform: uppercase; */
  border-bottom: none;
  margin-right: 0px;
  height: 32px;
  display: flex;
  align-items: center;
  border-right: 1px solid #dddddd;
}

.bp3-portal .bp3-dialog-header {
  background: white !important;

}

.bp3-portal .bp3-dialog-header .bp3-heading {
  color: #1e272e !important;
  justify-content: flex-start !important;
  font-size: 30px !important;
  font-weight: 600;
  font-family: "Roboto";
  min-height: 28px;
}

.bp3-portal .bp3-dialog-header  button:hover {
  background-color: white !important;
}

.bp3-portal .bp3-dialog {
  background: white;
  padding: 20px 25px 15px;
  border-radius: 10px;
}

.dock-tab:hover {
  color: inherit;
}

.dock-tab-active {
  background: #fff;
}

.dock-top .dock-bar {
  background: #f3f3f3;
  border-bottom: none;
  padding: 0;
}

.dock-nav-wrap {

}

.dock-ink-bar {
  top: 3px;
  display: none;
}

.dock-panel {
  border: none;
}

.dock-divider {
  background: #f3f3f3;
  opacity: 0;
  flex: 0 0 1px;
  transform: scale(12) !important;
}

.dock-tab > div {
  padding: 4px 14px 4px 4px;
  text-align: left;
}

.dock-tab .drag-initiator {
  padding-left: 5px;
}

.dock-tab-close-btn {
  opacity: 0;
  top: 9px;
}

.dock-tab-close-btn:before {
  position: absolute;
  right: 7px;
  top: -1px;
  color: #6C767E;
}

.dock-panel-max-btn:before {
  position: absolute;
  content: " ";
  border: 2px solid #f1ecec;
  border-radius: 1px;
  width: 16px;
  height: 16px;
  right: 6px;
}

.dock-panel-max-btn:hover {
  transform: none;
}

.dock-tab-close-btn:hover, .dock-tab-close-btn:focus {
  color: #666;
  opacity: 1;
  transform: none;
}

.dock-tab:hover .dock-tab-btn .drag-initiator .custom-icon-wrapp .dock-tab-refresh-btn {
  opacity: 1;
}

.dock-mbox .dock-panel-max-btn:before {
  font-size: 40px;
  color: #fff;
}
